<template>
  <a href="#contacts" class="call">Связаться с нами</a>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.call {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #4a2929;
  padding: 7px 28px;
  background: #f7f7f7;
  border-radius: 8px;
  align-self: center;
  cursor: pointer;
  text-decoration: none;
  display: block;
  @media (max-width: 1100px) {
    display: none;
  }
}
</style>
