<template>
  <div class="footer">
    <BaseLogo class="footer__logo" />
    <ul class="footer__menu">
      <li>
        <a href="#about"> О нас</a>
      </li>
      <li>
        <a href="#advantages"> Наши приемущества</a>
      </li>
    </ul>
    <ul class="footer__menu">
      <li>
        <a href="#services"> Наши услуги</a>
      </li>
      <li>
        <a href="#contacts"> Контакты</a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.footer {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-column-gap: 20px;
  @media (max-width: 880px) {
    grid-template-columns: 1fr;
    grid-row-gap: 0;
  }
  &__menu {
    list-style-type: none;
    li {
      margin-bottom: 28px;
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #4a2929;
        text-decoration: none;
      }
    }
  }
  &__logo {
    margin: 0 auto 40px auto;
  }
}
</style>
<script>
import BaseLogo from "@/components/BaseLogo";
export default {
  components: { BaseLogo },
};
</script>
