<template>
  <div class="services" id="services">
    <div class="services__title">Наши услуги</div>
    <div class="services__items">
      <div class="services__item">
        <div class="services__mark services__mark--green"></div>
        <div class="services__name">Досудебное взыскание</div>
      </div>
      <div class="services__item">
        <div class="services__mark services__mark--purple"></div>
        <div class="services__name">Судебное взыскание</div>
      </div>
      <div class="services__item">
        <div class="services__mark services__mark--orange"></div>
        <div class="services__name">Выездное взыскание</div>
      </div>
      <div class="services__item">
        <div class="services__mark services__mark--yellow"></div>
        <div class="services__name">
          Оценка портфелей на предмет перспектив взыскания
        </div>
      </div>
      <div class="services__item">
        <div class="services__mark services__mark--blue"></div>
        <div class="services__name">Юридическая поддержка</div>
      </div>
      <div class="services__item">
        <div class="services__mark services__mark--dark-pink"></div>
        <div class="services__name">
          Консталтинг в области управления проблемными активами
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.services {
  margin-bottom: 97px;
  @media (max-width: 480px) {
    margin-bottom: 48px;
  }
  &__title {
    font-weight: 860;
    font-size: 44px;
    line-height: 53px;
    text-align: center;
    color: #5f3b3b;
    margin-bottom: 48px;
    @media (max-width: 480px) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 24px;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 24px;
    @media (max-width: 880px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    position: relative;
    display: grid;
    background: #ffffff;
    box-shadow: 0 4px 58px rgba(0, 0, 0, 0.06);
    border-radius: 22px;
    height: 224px;
    padding: 30px;
    align-items: center;
  }
  &__name {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #4a2929;
    @media (max-width: 480px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__mark {
    position: absolute;
    top: 36px;
    right: 40px;
    width: 41px;
    height: 4px;
    border-radius: 39px;
    &--green {
      background: #0e8159;
    }
    &--purple {
      background: #6a2deb;
    }
    &--orange {
      background: #df8e14;
    }
    &--yellow {
      background: #e9d20a;
    }
    &--blue {
      background: #4ea8fc;
    }
    &--dark-pink {
      background: #d12597;
    }
  }
}
</style>
