<template>
  <ul class="menu">
    <li class="menu__item">
      <a class="menu__link" href="#about"> О нас</a>
    </li>

    <li class="menu__item">
      <a class="menu__link" href="#advantages">Наши приемущества</a>
    </li>
    <li class="menu__item">
      <a class="menu__link" href="#services">Наши услуги</a>
    </li>
    <li class="menu__item">
      <a class="menu__link" href="#contacts">Контакты</a>
    </li>
  </ul>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.menu {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0 auto;
  @media (max-width: 1100px) {
    display: none;
  }
  &__item {
    margin: 0 12px;
    padding: 0;
  }
  &__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #4a2929;
    text-decoration: none;
  }
}
</style>
