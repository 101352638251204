<template>
  <div class="advantages" id="advantages">
    <div class="advantages__title">Наши приемущества</div>
    <div class="advantages__items">
      <div class="item">
        <div class="item__number"><img src="@/assets/1.png" /></div>
        <div class="item__title">Безопасность партнера</div>
        <div class="item__desc">
          Любые репутационные риски будут лежать на нас
        </div>
      </div>
      <div class="item">
        <div class="item__number"><img src="@/assets/2.png" /></div>
        <div class="item__title">Слаженный механизм</div>
        <div class="item__desc">
          Произведена максимальная стандартизация и автоматизация работ
        </div>
      </div>
      <div class="item">
        <div class="item__number"><img src="@/assets/3.png" /></div>
        <div class="item__title">Авторский подход</div>
        <div class="item__desc">
          При взыскании используются собственные авторские методики
        </div>
      </div>
      <div class="item">
        <div class="item__number"><img src="@/assets/4.png" /></div>
        <div class="item__title">Эффективность</div>
        <div class="item__desc">
          Используется максимальное колличество инструментов воздействия на
          должника
        </div>
      </div>
      <div class="item">
        <div class="item__number"><img src="@/assets/5.png" /></div>
        <div class="item__title">Законность</div>
        <div class="item__desc">
          Все действия производятся в рамках действующего законодательства
        </div>
      </div>
      <div class="item">
        <div class="item__number"><img src="@/assets/6.png" /></div>
        <div class="item__title">Информативность партнера</div>
        <div class="item__desc">
          Партнерам предоставляется обширная информация: аналитические
          материалы, аудио/видео-информация и т.д.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.advantages {
  margin-bottom: 100px;
  @media (max-width: 480px) {
    margin-bottom: 32px;
  }
  &__title {
    font-weight: 860;
    font-size: 44px;
    line-height: 53px;
    text-align: center;
    color: #4a2929;
    margin-bottom: 24px;
    @media (max-width: 480px) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 0;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 72px;
    padding: 70px 0 0 0;
    @media (max-width: 880px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
}

.item {
  border: 3px solid #65bb9d;
  border-radius: 22px;
  padding: 60px 28px 32px 28px;
  position: relative;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #4a2929;
    margin-bottom: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4a2929;
  }
  &__number {
    position: absolute;
    top: -44px;
    left: 28px;
  }
}
</style>
