<template>
  <div class="about" id="about">
    <div class="about__body">
      <div class="about__title">Кто мы такие?</div>
      <div class="about__desc">
        <p>
          Мы действующее коллекторское агенство в Узбекистане. Мы обладаем
          экспертизой реального взыскания. У нас есть все необходимые
          инструменты, включая штат юристов и взыскателей.
        </p>
        <p>
          Мы управляем «проблемными» банковскими и МФО активами и
          специализируемся на «портфельном» взыскании задолженности физических
          лиц по кредитам в больших объемах на всей территории Узбекистана.
        </p>
        <p>
          Компания основана в 2021 году. С тех пор мы научились обеспечивать
          процедуру взыскания по просроченным банковским кредитам, используя при
          этом только законные инструменты.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.about {
  width: calc(100% - 128px);
  background: #ffffff;
  box-shadow: 0 4px 58px rgba(0, 0, 0, 0.13);
  border-radius: 44px;
  padding: 50px 0 28px 0;
  margin: 0 auto 80px auto;
  @media (max-width: 480px) {
    padding: 16px 0;
    margin: 0 auto 32px auto;
  }
  &__body {
    background: linear-gradient(
      254.31deg,
      #65bb9d -4.2%,
      #00784e 99.46%,
      rgba(101, 187, 157, 0) 99.47%
    );
    border-radius: 44px;
    padding: 60px 50px;
    z-index: 10;
    width: calc(100% + 128px);
    margin-left: -64px;

    @media (max-width: 480px) {
      padding: 25px 12px;
    }
  }
  &__title {
    font-weight: 860;
    font-size: 44px;
    line-height: 53px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 44px;
    @media (max-width: 480px) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 32px;
    }
  }
  &__desc {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    @media (max-width: 480px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>
